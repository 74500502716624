.LOGIN-PAGE {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.div {
  background-color: #ffffff;
  height: 600px;
  position: relative;
  width: 400px;
}

.overlap {
  height: 156px;
  left: 15px;
  position: absolute;
  top: 28px;
  width: 372px;
}


.text-wrapper {
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  left: 99px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 126px;
  white-space: nowrap;
}

.text-wrapper-2 {
  color: #000000;
  font-family: "Inter-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 122px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 196px;
  white-space: nowrap;
}

.group,
.overlap-wrapper {
  height: 60px;
  left: 28px;
  position: absolute;
  width: 344px;
}

.group {
  top: 256px;
}

.overlap-wrapper {
  top: 338px;
}

.overlap-group {
  background-color: #5783DB;
  border-radius: 10px;
  height: 60px;
  position: relative;
  width: 342px;
}

.at-sign,
.person {
  height: 24px;
  left: 16px;
  position: absolute;
  top: 18px;
  width: 24px;
}

.text-wrapper-3,
.text-wrapper-4 {
  background-color: #5783DB ;
  border: none;
  color: #ffffff;
  font-family: "Inter-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 56px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
  width: 270px;
  resize: none;
  outline: none;
}

.sign-in-button {
  background-color: #5783DB;
  border: none;
  border-radius: 10px;
  height: 60px;
  left: 28px;
  position: absolute;
  top: 428px;
  width: 344px;
  cursor: pointer;
}

.text-wrapper-5 {
  color: #ffffff;
  font-family: "Inter-SemiBold", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 148px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

::placeholder {
  color: #000000;
  opacity: 1; /* Firefox */
}

#popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Content */
.popup-content {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 300px;
  padding: 20px;
  text-align: center;
}

/* Close Button */
.close-button {
  position: center;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #1877F2; /* Facebook blue */
}



/* Popup Body */
.popup-body {
  margin-top: 20px;
}

.popup-body p {
  font-size: 16px;
  color: #1C1E21;
  margin-bottom: 20px;
}

/* Install Button */
.add-to-home-screen-button {
  background: #1877F2;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.add-to-home-screen-button:hover {
  background: #166FE5;
}

.text-wrapper-6 {
  font-weight: 600;
}