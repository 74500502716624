/* Menu de cima */
.menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px; /* Ajuste a altura desejada */
    background: linear-gradient(180deg, #fff, #d9d9d9);
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    z-index: 6666; /* Para garantir que o menu fique acima de outros elementos */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
  
  .menu::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #fff, #d9d9d9);
    z-index: -1;
  }
  
  .menu a {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
  }
  
  .menu a:hover {
    background: linear-gradient(180deg, #fff, #d9d9d9);
  }
  
  .menu a img {
    max-width: 100%; /* Ajuste o tamanho máximo da imagem */
    max-height: 100%; /* Ajuste o tamanho máximo da imagem */
    display: block;
  }
  
  .menu a:nth-child(1) img {
    max-width: 100%; /* Ajuste o tamanho da imagem do botão 1 */
  }
  
  .buttons-container {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .valor-box {
    width: 150px;
    height: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
    align-items: center;
    line-height: 20px;
    display: block;
    margin: 0 auto;
  }
  
  .valor-box-saque {
    width: 90%;
    height: 100px;
    background-color: #ccc;
    border-radius: 12px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    align-items: center;
    line-height: 20px;
    display: block;
    margin: 0 auto;
    background-image: linear-gradient(125deg, #dedede, #ccc);
  }
  
  .valor-box span {
    font-weight: bold;
    font-size: 17px;
  }
  
  #valor {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 21px;
    width: 90%;
    height: 5%;
  }
  
  #valor-saque {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 21px;
  }
  
  /* Slogan inicio */
  .slogan-inicio {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    background-color: #7a7a7a;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    height: 50px;
    width: 90%;
    border-radius: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  /* Notificação de dinheiro */
  .notificacaoDinheiro {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
    animation: fadeOut 3s forwards;
  }
  
  .notificacaoDinheiro img {
    width: 100px;
    height: 100px;
  }
  
  .notificacaoDinheiro span {
    color: white;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    80% { opacity: 1; }
    100% { opacity: 0; display: none; }
  }
  
  /* POPUP LIMITE DE SAQUE */
  /* Popup container */
.popup-containerL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  z-index: 1000;
  width: 300px;
  text-align: center;
  visibility: visible; /* Default is visible when triggered */
}

/* Hide the popup initially */
.popup-containerL.hidden {
  visibility: hidden;
}

/* Popup text */
.popup-textL {
  font-size: 18px;
  margin-bottom: 15px;
  color: #333;
}

/* Button to unlock */
.desbloquear-buttonL {
  background-color: #0178f6;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.desbloquear-buttonL:hover {
  background-color: #005bb5;
}

/* Disable rating buttons when limit is reached */
.rating-button:disabled {
  background-color: lightgray;
  color: white;
  cursor: not-allowed;
}

  .desbloquear-buttonL {
    background-color: #007bff;
    width: 95%;
    border-radius: 8px;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
  }
  
  /* Barra de carregamento */
  #progress-bar {
    width: 95%;
    height: 20px;
    background-color: #ddd;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 auto;
  }
  
  #progress-bar div {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #ff0000;
    animation: progress 86400s linear; /* ajustar segundos */
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
  
  @keyframes progress {
    from { width: 0%; }
    to { width: 100%; }
  }
  
  /* Rating buttons */
  .rating-buttons {
    display: flex;
    justify-content: center;
    gap: 3px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 5px;
    box-sizing: border-box;
  }
  
  .rating-button {
    background-color: #5783DB;
    border: none;
    color: white;
    padding: 8px 3px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
  
  .rating-button:hover {
    background-color: #45a049;
  }


.logo {
    height: auto;
    width: 25%;
    position: absolute;
    top: 10px;
    left: 10px;
    object-fit: fill;
}

.notificacaoDinheiro {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 15px 20px;
  display: flex;
  align-items: center;
  animation: popIn 0.3s ease-out, fadeOut 0.3s ease-in 2.7s;
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.notificacaoDinheiro img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.notificacaoDinheiro span {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #4CAF50;
}

@media (max-width: 768px) {
    .logo {
        width: 30%;
    }
}

@media (max-width: 480px) {
    .logo {
        width: 35%;
    }
}
  
  @media (max-width: 480px) {
    .rating-buttons {
      gap: 2px;
    }
  
    .rating-button {
      font-size: 10px;
      padding: 6px 2px;
    }
  }
  
  @media (max-width: 360px) {
    .rating-button {
      font-size: 9px;
      padding: 5px 1px;
    }
  }
  
  @media (max-width: 320px) {
    .rating-buttons {
      gap: 1px;
    }
  
    .rating-button {
      font-size: 8px;
      padding: 4px 1px;
    }
  }

