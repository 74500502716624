.button-container {
    display: flex;
    margin: 0 auto;
    width: 90%;
    justify-content: center;
    align-items: center;
    }
    
.square-button {
border-radius: 12px;
border: none;
aspect-ratio: 1 / 1;
margin: 5px;
background-color: #ccc;
color: #ccc;
cursor: pointer;
transition: transform 0.2s, background-color 0.2s;
box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.square-button img {
width: 100%;
height: 100%;
object-fit: cover; /* Redimensiona a imagem para preencher o botão */
}

.square-button.active {
background-color: #007bff;
transform: scale(1.2);
}


/* Withdraw section title */
.withdraw-title {
    font-size: 20px;
    font-weight: 600;
    color: #333; /* Dark text for contrast */
    margin-bottom: 10px;
    text-align: center;
}

/* Styling for the balance value */
.valor-box-saque {
    background-color: transparent; /* Transparent background */
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 15px;
    border: 2px solid  #5783DB; /* Thin green border */
    text-align: center;
}

/* Withdraw section title */
.withdraw-title {
    font-size: 20px;
    font-weight: 700;
    color:  #5783DB; /* Green title for balance */
    margin-bottom: 15px;
    letter-spacing: 1px; /* Slightly spaced letters for modern feel */
}

/* Styling for the balance value */
.valor-saque {
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #333; /* Darker color for the balance amount */
    letter-spacing: -1px; /* Slight spacing to tighten numbers */
    margin-bottom: 0;
}

/* Currency symbol styling */
.valor-saque::before {
    font-size: 30px;
    vertical-align: top;
    margin-right: 5px;
    color:  #5783DB; /* Green currency symbol */
}

/* Optional hover effect for the box */
.valor-box-saque:hover {
    border-color: #333; /* Border changes color on hover */
    color:  #5783DB; /* Text changes color on hover */
    cursor: pointer;
}
.error-message {
    color: red;
    font-size: 16px;
    background-color: #f8d7da;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0 20px 0; /* Added margin above and below to create spacing */
    border: 1px solid #f5c6cb;
    }        